<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-input-group class="mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model.lazy="searchword"
                :placeholder="$t('administration.table.settings.search') + '...'"
                @input="search"
              />
              <b-button
                v-if="$ability.can('store', 'categories')"
                variant="primary"
                :to="{ name: 'product-categories-store' }"
              >
                <span class="text-nowrap">{{ $t('general.add') }}</span>
              </b-button>
            </b-input-group>

            <v-tree
              ref="tree"
              :can-delete-root="true"
              :data="categories"
              :draggable="false"
              :expanded="true"
              :tpl="tpl"
              :halfcheck="false"
              :multiple="false"
              @del-node="destroy"
            />
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import SweetAlertMixin from '@mixins/SweetAlertMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { VTree } from 'vue-tree-halower'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    VTree,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      tableColumns: [
        { key: 'title', label: this.$t('administration.table.fields.title'), sortable: false },
        { key: 'actions', label: this.$t('administration.table.fields.actions') },
      ],
      searchword: '',
      categories: [],
    }
  },
  beforeCreate() {
    this.$http.get('/api/administration/categories', {
      params: {
        type: 'product',
      },
    })
      .then(response => {
        this.categories = response.data
      })
  },
  methods: {
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index } = args

      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'

      if (node.searched) {
        titleClass += ' node-searched'
      }

      const updateRoute = { name: 'product-categories-update', params: { id: node.id } }
      const frontRoute = `${process.env.VUE_APP_APP_URL}/category/${node.slug}`

      return <div style="display: flex;align-items: flex-start;justify-content: space-between;width: 100%;">
              <span class={titleClass} domPropsInnerHTML={node.title}></span>
              <div style="float:right;">
              <a href={frontRoute} target="_blank" class="btn btn-outline-secondary btn-sm" style="margin-right:10px;"><feather-icon icon="EyeIcon" size="14" className="mr-50"/></a>
              <router-link to={updateRoute} class="btn btn-primary btn-sm"><feather-icon icon="EditIcon" size="14" className="mr-50"/></router-link>
              <button style="margin-left:10px;" class="btn btn-danger btn-sm" onClick={() => this.$refs.tree.delNode(node, parent, index)}><feather-icon icon="Trash2Icon" size="14" className="mr-50"/></button>
              </div>
              </div>
    },
    destroy(node) {
      this.confirm(() => {
        this.$http.delete(`/api/administration/categories/${node.delNode.id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    fetchData() {
      this.$http.get('/api/administration/categories', {
        params: {
          type: 'product',
        },
      })
        .then(response => {
          this.categories = response.data
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>
